/*******************************
         Site Overrides
*******************************/

.ui.blue.segment {
  background-color: #0e1a2b !important;
}

.ui.blue.segment:not(.inverted) {
  border: 0 !important;

}

.ui.horizontal.segments {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-radius: 0px;
  padding: 0em;
  background: none;
  box-shadow: @boxShadow;
  margin: @margin;
  border-radius: @borderRadius;
  border: @border;
}