/*******************************
         Site Overrides
*******************************/
.ui.inverted.menu.blue.sidebar .blue.active.item.sidebar, .ui.inverted.menu.blue.sidebar {
  background-color: #162840 !important;
}

.ui.menu {
    border-radius: 0px !important;
}

:focus-visible {
    outline: none;
}

.alarm-container.dashboard {
  height: auto;
}

.alarm-container {
  height: 640px;
  width: 100%;
  padding-left: 10px;

}

.DateRangePickerInput__withBorder{
  border-radius: 4px;
}
.DateInput {
  width: 90px;
}
.DateInput_input{
  padding: 0;
  font-size: 1em;
  text-align: center;
}
.DateRangePicker_picker {
  top: 42px !important;
  transition: all 0.3s
}
.DateInput_fang {
  top: 31px !important;
}
.CalendarDay__selected {
  background: #0e1a2b;
  border-color: #4bef8f
}
.CalendarDay__selected:hover {
  background: #33dacd;
  border-color: #33dacd
}
.CalendarDay__selected_span {
  background: #1d3556; 
  border-color: #4bef8f
}

.blurring.dimmed.dimmable > :not(.dimmer) {
    -webkit-filter: blur(2px) grayscale(0.7) !important;
    filter: blur(2px) grayscale(0.7) !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.chart {
  position: absolute;
  border-radius: 60px;
  border-width: 60px;
  border-style: solid;
  border-color: transparent;
}

.chart.top:hover {
  border-top-color: red !important;
}
.chart.top {
  border-top-color: blue !important;
}



.chart_shadow {
  position: absolute;
  border-style: solid;
  border-color: transparent;
  border-radius: 60px;
  border-width: 60px;
  box-shadow: 4px 5px 11px 0px rgb(14 26 43 / 70%);
}


#circlebg {
    width: 150px;
    height: 150px;
    border-radius: 50% 50%;
    position: absolute;
    top: 0px;
    left: 30px;
    box-shadow: 4px 5px 11px 0px rgb(14 26 43 / 70%);
}

.circle_segment:hover div.lbl {
    display: block;   

}

.circle_segment {
    transition: 0.3s;
    opacity: 0;
    border-radius: 75px 0 0 75px;
    width: 75px;
    height: 150px;
    transform-origin: 100% 50%;
    transform: rotate(90deg); 
    pointer-events: auto;
    cursor: pointer;
  background: #4BEF8F;

}

.circle_segment.active {
    opacity: 1;
    background: #1d3556;
     cursor: unset;
}


.circle_segment:hover {
  opacity: 1;
}

.sw {
  top: 0;
  left: 30px;
  transform-origin: 100% 50%;
  width: 75px;
  height: 150px;
  position: absolute;
  overflow: hidden;
  pointer-events: none;    
}
