/*******************************
         Site Overrides
*******************************/

.ui.input.slider.thumb {
  margin:auto;
}

.ui.input.slider.thumb:nth-child(1) {
  width: 0;
  height: 18px;
  border: 2px solid #FFF500;
}

.ui.input.slider.thumb:nth-child(2) {
  height: 18px;
  width: 0;
  border: 2px solid #FD1602;
}

.ui.input.slider.thumb:nth-child(3) {
  height: 10px;
  width: 0;
  border: 1px solid #000000;
  opacity: 100%;
}

.ui.input.slider.thumb:nth-child(4) {
  height: 10px;
  width: 0;
  border: 1px solid #000000;
  opacity: 100%;
}
