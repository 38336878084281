/*******************************
        User Overrides
*******************************/

.ui.dropdown .menu {
        color: white !important;
        background-color: #162840 !important;        
}
.ui.dropdown .item:hover {
        color: #4bef8f !important;
}
.ui.dropdown .menu .selected.item {
    color: white;
}

.ui.fluid.dropdown.sensor {
  display: flex;
  flex-wrap: wrap;
  border-radius:0;
}
.ui.multiple.dropdown.sensor > .label {
  display: flex;
  padding: 1px 8px 2px 1px;
  
}
.ui.scrolling.dropdown .menu {
    max-height: calc(100vh - 250px) !important;
    background-color: #162840;
    max-width: 400px !important;
    min-width: unset !important;
}

.ui.fluid.dropdown.sensor:hover {
        color: #4BEF8F !important;
}
.ui.dropdown .menu > .item {
        color: white;
}