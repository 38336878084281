/*******************************
         Site Overrides
*******************************/

.sensor_1:hover {
  background-color: #999
}

.table tr.visible.transition {
  display: table-row !important;
}

.ui.inverted.blue.table {
    background-color: #0E1A2B !important;
}


.ui.inverted.table tr td {
    border: none !important; 
}

.ui.fixed.table td {
  overflow: unset;
}


