/*******************************
         Site Overrides
*******************************/


i.large.icon,
i.large.icons {
  line-height: 1;
  vertical-align: middle;
  font-size: @large;
}

i.large.icon.trigger,
i.large.icons.trigger {
  line-height: 1;
  vertical-align: middle;
  font-size: @large;
  color: @gray;
  
}

i.large.icon.hiding,
i.large.icons.hiding {
  display:none;
}


i.big.icon.trigger,
i.big.icons.trigger {
  line-height: 1;
  vertical-align: middle;
  font-size: @large;
  color: @gray;
  
}

i.big.icon.hiding,
i.big.icons.hiding {
  display:none;
}

.over{
color:"red";
}

.under{
color:"blue";
}

i.icon.connect {
  cursor: pointer;
}
i.icon.connect:hover {
  color: #4bef8f;
}