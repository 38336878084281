/*******************************
         Site Overrides
*******************************/

.column.click-graph {
  width: 23%!important;
  padding-left: 0 !important

}
.column.click-graph:hover {
    border: 2px solid rgba(255, 255, 0, 0.533);
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
}