/*******************************
         Site Overrides
*******************************/

.ui .button.align-right {
  z-index: 1;
  position: absolute;
  right: 0;
}


.ui.button.login {
  width:90%;
  margin:0 auto;
  background: @green;
}

.secondary-menu-button {
  background-color: #162840 !important;
  color: #ffffff !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 1px solid #393d40 !important;
}


.ui.icon.buttons .button > .icon,
.ui.icon.button > .icon {
    font-size: 15px ;
    line-height: 12px;
    margin: auto 10px !important;
}

.ui.icon.button.mini > .icon {
    margin: auto 10px auto auto !important;
}

.ui.inverted.blue.basic.button.mini:focus {
    box-shadow: 0px 0px 0px 2px #fff inset !important;
    color: #FFFFFF !important;
}



.ui.button.close_menu  {
  box-shadow: none !important;
}

.ui.icon.close_menu .icon {
    margin: 0 !important;

}

.secondary-menu-button:hover {
    background: rgba(22, 40, 64, 0.8) !important;
    color: #4BEF8F !important;
}

.ui.inverted.sensor_selector:focus {
  box-shadow:unset !important;
  color: unset;
}

.ui.inverted.white.basic.active.button {
  box-shadow: 0px 0px 0px @invertedBorderSize white inset !important;
  color: white !important;
}

.active.button.analytics {
  text-decoration: underline;
}

.ui.inverted.blue.basic.button:hover {
    box-shadow: 0px 0px 0px 2px #fff inset !important;
    color: #fff !important;
}

.ui.basic.inverted.active.button.pink {
  color: #ff26c2 !important;
  box-shadow: 0px 0px 0px 2px #ff26c2 inset !important;
}


.ui.basic.inverted.active.button.xanadu {
  color: #6b7d5f !important;
  box-shadow: 0px 0px 0px 2px #6b7d5f inset !important;
}
.ui.basic.inverted.active.button.gold {
  color: #FFD700 !important;
  box-shadow: 0px 0px 0px 2px #FFD700 inset !important;
}
.ui.basic.inverted.active.button.sunglow {
  color: #ffc83b !important;
  box-shadow: 0px 0px 0px 2px #ffc83b inset !important;
}
.ui.basic.inverted.active.button.glue {
  color: #1d3556 !important;
  box-shadow: 0px 0px 0px 2px #1d3556 inset !important;
}
.ui.basic.inverted.active.button.cgBlue {
  color: #337793 !important;
  box-shadow: 0px 0px 0px 2px #337793 inset !important;
}

.ui.basic.inverted.active.button.maximumBlue {
  color: #48b8d0 !important;
  box-shadow: 0px 0px 0px 2px #48b8d0 inset !important;
}
.ui.basic.inverted.active.button.middleBlue {
  color: #59bed4 !important;
  box-shadow: 0px 0px 0px 2px #59bed4 inset !important;
}
.ui.basic.inverted.active.button.darkBlue {
  color: #0E1A2B !important;
  box-shadow: 0px 0px 0px 2px #0E1A2B inset !important;
}
.ui.basic.inverted.active.button.menuBlue {
  color: #162840 !important;
  box-shadow: 0px 0px 0px 2px #162840 inset !important;
}


.ui.basic.inverted.active.button.red {
  color: #FF976f !important;
  box-shadow: 0px 0px 0px 2px #FF976f inset !important;
}

.ui.basic.active.button.red {
  color: #b85a36 !important;
  box-shadow: 0px 0px 0px 2px #b85a36 inset !important;
}

.ui.basic.inverted.active.button.pink {
  color: #FF8CD7 !important;
  box-shadow: 0px 0px 0px 2px #FF8CD7 inset !important;
}

.ui.basic.inverted.button.pink {
  color: #ba4c97 !important;
  box-shadow: 0px 0px 0px 2px #ba4c97 inset !important;
}
.ui.basic.inverted.active.button.yellow {
  color: #FFD700 !important;
  box-shadow: 0px 0px 0px 2px #FFD700 inset !important;
}

.ui.basic.inverted.active.button.green {
  color: #00FF00 !important;
  box-shadow: 0px 0px 0px 2px #00FF00 inset !important;
}

.ui.basic.inverted.button.green {
  color: #008a00 !important;
  box-shadow: 0px 0px 0px 2px #008a00 inset !important;
}

.ui.basic.inverted.active.button.blue {
  color: #00FFFF !important;
  box-shadow: 0px 0px 0px 2px #00FFFF inset !important;
}

.ui.basic.inverted.button.blue {
  color: #008486 !important;
  box-shadow: 0px 0px 0px 2px #008486 inset !important;
}

.ui.basic.inverted.active.button.aqua {
  color: #00ffff !important;
  box-shadow: 0px 0px 0px 2px #00ffff inset !important;
}
