/*******************************
         Site Overrides
*******************************/


.ui.inverted.menu .active.item {
  background: @invertedActiveBackground;
  color: @invertedActiveColor !important;
  font-weight: @itemActiveFontWeight;
}

.ui.link.inverted.menu .item:hover,
.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu .link.item:hover,
.ui.inverted.menu a.item:hover {
  background: @invertedHoverBackground;
  color: @invertedHoverColor;
  font-weight: @itemActiveFontWeight;
}

.ui.inverted.menu .active.item:hover {
  background: @invertedActiveHoverBackground;
  color: @invertedActiveHoverColor !important;
  font-weight: @itemActiveFontWeight;
}
.ui.inverted.pointing.menu .active.item:hover:after {
  background: @invertedArrowActiveHoverColor !important;
  font-weight: @itemActiveFontWeight;
}


.ui.labeled.icon.menu {
    background-color: #162840;
    margin:1px;
}

.ui.dropdown .menu {
    left: 0 !important;
    right: 0 !important;
}