/*******************************
        User Overrides
*******************************/

.blue .ui.list > .item .header {
        color: white;
        margin-top: 20px;
}
.blue .ui.segment {
        color: white;
        background-color: #0e1a2b;
}

.blue .ui.tabular.menu .item {
        color: white;
}

.blue .ui.tabular.menu .active.item {
        color: #0e1a2b;
}

.ui.inverted.blue.tabular.menu .active.item {
    background-color: #0E1A2B !important;
}
.ui.inverted.blue.tabular .item {
    background-color: rgba(0, 0, 0, 0.1) !important;
}


.ui.blue.attached.tabular.menu {
    background-color: unset !important;
}

.ui.tab.cage_panel {
    min-height: 85px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    border: none;
    padding-bottom: 10px;
    border-top-right-radius: 7px;
}

.ui.tab.cage_panel.transparent {
        background: rgba(0,0,0,0) !important;
}