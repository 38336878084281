/*******************************
         Site Overrides
*******************************/

.ui .label.sensor_selector {
    width: 185px !important;
    padding-left: 10px;
}
.ui .sensor_selector .detail {
    width: 115px !important;
    right: 10px;
    position: absolute;
    text-align: center;
    padding: 0.5em 0.833em !important;
}

.ui.orange.label {
    color: black !important;
}