/*******************************
         Site Overrides
*******************************/
.ui.container.hiding {
  display: none;
  position:absolute;
  
}

.ui.container.show{
    display: block;
    position:absolute;
    left:-10px;
}

.ui.container.alarmtextgroup{
  display: flex;
  flex-direction: ltr;
  width: 100%;
}

.ui.container.dashboard_menu{
  margin: 1em !important;
  width: auto !important;
  overflow: hidden !important;
}