/*******************************
        User Overrides
*******************************/


.ui.inverted.segment,
.ui.primary.inverted.segment.alarminfo {
  color: @invertedTextColor;
  padding: 0;
  margin:0;
}

.ui.accordion .yellow {
  box-shadow: 0px 0px 15px rgba(255,255,0, 0.7);
  border-radius: 5px;
  margin-right: 5px;
}
.ui.accordion .yellow:hover {
  box-shadow: 0px 0px 15px rgba(255,255,0, 1);
}
.ui.accordion .active.yellow {
  box-shadow: 0px -7px 7px rgba(255,255,0, 0.7);
}
.ui.accordion .active.yellow:hover {
  box-shadow: 0px -7px 7px rgba(255,255,0, 1);
}


.ui.accordion .red {
  box-shadow: 0px 0px 15px rgba(255, 0, 0, 0.7);
  border-radius: 5px;
  margin-right: 5px;
}
.ui.accordion .red:hover {
  box-shadow: 0px 0px 15px rgba(255, 0, 0, 1);
}
.ui.accordion .active.red {
  box-shadow: 0px -7px 7px rgba(255, 0, 0, 0.7);
}
.ui.accordion .active.red:hover {
  box-shadow: 0px -7px 7px rgba(255, 0, 0, 1);
}


.ui.accordion .none:hover {
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
}
.ui.accordion .active.none {
  box-shadow: 0px -7px 7px rgba(255, 255, 255, 0.7);
}
.ui.accordion .active.none {
  box-shadow: 0px -7px 7px rgba(255, 255, 255, 1);
}


.ui.accordion .title.none {
  cursor: default;
}