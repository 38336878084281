/*******************************
    User Variable Overrides
*******************************/

.alarm.ui.card {
  margin-bottom: 30px;
  transition: all 0.5s linear;
}
.alarm.ui.card.dismissed {
    display: none;
}

.alarm.severity0.ui.card {
    background-color: @blue;
}
.alarm.severity1.ui.card {
    background-color: @yellow;
}
.alarm.severity2.ui.card {
    background-color: @red;
}



.ui.card.stacked.two, .ui.card.stacked.two:hover{
  box-shadow: rgba(29, 53, 86, 0.85) 0px 10px 1px
}
.ui.card.stacked.three, .ui.card.stacked.three:hover {
  box-shadow: rgba(29, 53, 86, 0.7) 1px 10px 1px, rgba(29, 53, 86, .6) 2px 20px 1px;
}

.alarm.ui.card .extra.content  {
    color: @white !important;
    margin-bottom: 1em;
    text-transform: uppercase!important;
}

.alarm.ui.card .header, .alarm.ui.card .description {
    color: @white !important;
}
.alarm.ui.card, .alarm.ui.card:hover {
    box-shadow: none;

}

.alarm .ui.basic.buttons .button, .alarm .ui.basic.buttons .button:hover  {
    color: @black !important;
    background-color: inherit !important;
}

.alarm .ui.basic.buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}
.alarm .ui.basic.buttons .button {
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    color: @white !important;
}

.alarminfo.ui.card {
  background:#0e1a2b !important;
  border-style: solid;
  border-color: @green;
  border-width: 1px;
}

